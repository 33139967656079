import React from "react"
import { Box, Text } from "theme-ui"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import SubInfoBox from "../../components/SubInfoBox"
import ContentSection from "../../components/ContentSection"
import SEO from "../../components/SEO"
import ReferenceLink from "../../components/UpscReference/ReferenceLink"

const links = [
  {
    text: "India’s Ancient Past",
    href: "http://amzn.to/2n6bSBX",
  },
  {
    text: "History Of Medieval India",
    href: "http://amzn.to/2n6bIds",
  },
  {
    text: "India’s Struggle For Independence",
    href: "http://amzn.to/2mryrnr",
  },
  {
    text: "History Of Modern India",
    href: "http://amzn.to/2nQDE5i",
  },
  {
    text: "Indian and World Geography for Civil Services",
    href: "http://amzn.to/2nQJ1Bp",
  },
  {
    text: "Geography of India",
    href: "http://amzn.to/2mT9YTX",
  },
  {
    text: "Oxford School Atlas",
    href: "http://amzn.to/2nlGIs3",
  },
  {
    text: "Facts of Indian Culture",
    href: "http://amzn.to/2nc55bt",
  },
  {
    text: "The Wonder That Was India",
    href: "http://amzn.to/2nc6dMk",
  },
  {
    text: "Indian Polity 5th Edition",
    href: "http://amzn.to/2nbXvNX",
  },
  {
    text: "Indian Economy 8th Edition",
    href: "http://amzn.to/2nc67En",
  },
  {
    text: "Science and Technology in India",
    href: "http://amzn.to/2nc4HK4",
  },
  {
    text: "Economic Survey",
    href: "http://amzn.to/2nQG2cl",
  },
  {
    text: "Environmental Studies from Crisis to Cure 2nd Edition",
    href: "http://amzn.to/2nc3W3y",
  },
  {
    text: "Science and Technology in India",
    href: "http://amzn.to/2nc4HK4",
  },
  {
    text: "General Studies: Paper I (2020)",
    href:
      "https://www.amazon.in/General-Studies-Services-Preliminary-Examination/dp/9389310628/ref=sr_1_3?dchild=1&keywords=General+Studies+-+Paper+I+for+Civil+Services+Preliminary+Examination+%282020%29&qid=1602502637&s=books&sr=1-3",
  },
  {
    text: "General Studies: Paper II (2020)",
    href:
      "https://www.amazon.in/General-Studies-Paper-2020-Examinations/dp/9353167779/ref=sr_1_1?dchild=1&keywords=General+Studies+-+Paper+II+for+Civil+Services+Preliminary+Examination+%282020%29&qid=1602325451&s=books&sr=1-1",
  },
  {
    text: "Analytical Reasoning",
    href: "http://amzn.to/2mTmfYD",
  },
  {
    text: "Verbal & Non-Verbal Reasoning",
    href: "http://amzn.to/2nIeMzJ",
  },
  {
    text: "India Year Book 2020",
    href:
      "https://www.amazon.in/India-2020-Publications-Division/dp/8123032390/ref=sr_1_1?dchild=1&qid=1602325097&refinements=p_27%3APublication+Division&s=books&sr=1-1",
  },
  {
    text: "Manorama Yearbook 2020",
    href:
      "https://www.amazon.in/Malayala-Manorama-English-Yearbook-2020/dp/8194229995/ref=sr_1_1?dchild=1&qid=1602325033&refinements=p_27%3AMammen+Mathew&s=books&sr=1-1",
  },
]

const UpscReferencePage = (): JSX.Element => (
  <Box>
    <SEO title="UPSC Reference - Prelims" />
    <Layout>
      <PageHeader>
        <Text sx={{ fontSize: 5, fontWeight: "bold", textAlign: "center" }}>
          UPSC Reference Material - Prelims
        </Text>
      </PageHeader>
      <SubInfoBox>
        Get the latest UPSC Reference Material prepared by Sarkar IAS Academy
      </SubInfoBox>
      <ContentSection
        header="Quick links for UPSC Reference Material - Prelims"
        noHeaderBorder={true}
        body={
          <Box mt={[-4, 0]}>
            {links.map(link => (
              <ReferenceLink name={link.text} href={link.href} />
            ))}
          </Box>
        }
      />
    </Layout>
  </Box>
)

export default UpscReferencePage
